export const achievementData = {
    bio : "This is my few quantified achievements on resume is great, but I just don’t work with hard numbers. I have data to support the quality of my work. I have lots of relevant experience.",
    achievements : [
        {
            id : 1,
            title : 'Google Cloud certification',
            details : 'Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.',
            date : 'May 29, 2021',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'
        },
        {
            id : 2,
            title : 'AWS Certification',
            details : 'Validate technical skills and cloud expertise to grow your career and business.',
            date : 'Jun 20, 2021',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 3,
            title : 'Google Certification',
            details : 'Google Play Academy Certification.',
            date : 'Jan 26, 2022',
            field : 'Automation',
            image : 'https://kstatic.googleusercontent.com/files/4c7093630487f14f9e372a2332dc210bb70a5874758f8823ef162d27fd2fe28cf2bcbb94389c072f0be2e3d7f5184e9d697e9e96e3786c4ad1c1f538fd582a1a'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/