export const experienceData = [
    // {
    //     id: 1,
    //     company: 'Globex Corporation',
    //     jobtitle: 'Frontend Developer',
    //     startYear: '2018',
    //     endYear: '2019'
    // },
    {
        id: 2,
        company: 'Noitavonne Software Company',
        jobtitle: 'Internship (Web Developer)',
        startYear: '2019',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'Zaad Foundation',
        jobtitle: 'Technical Head',
        startYear: '2020',
        endYear: 'Present'
    },
]