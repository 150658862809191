export const educationData = [
    {
        id: 1,
        institution: 'Govindrao Nikam Secondary School',
        course: '10th (Secondary Examination)',
        startYear: '2016',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'Shri Tripura Junior Science College',
        course: '12th (Senior Secondary Examination)',
        startYear: '2018',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'Sanjay Ghodawat University',
        course: 'Bachelor of Technology',
        startYear: '2019',
        endYear: 'Present'
    },
]