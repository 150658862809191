import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
// import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Wifi Dos',
        projectDesc: 'Wi-Dos is a firmware that performs Deauth Attacks, A Deauthentication attack is often confused with WiFi-jamming, as they block user from accessing Wi-Fi networks',
        tags: ['Python'],
        code: 'https://github.com/Adityaraj6/Wi-Dos',
        demo: 'https://github.com/Adityaraj6/Wi-Dos',
        image: one
    },
    {
        id: 2,
        projectName: 'VPLE',
        projectDesc: 'VPLE is an intentionally vulnerable Linux virtual machine. This VM can be used to conduct security training, test security tools, and practice common penetration testing Labs',
        tags: ['Operting System'],
        code: 'https://github.com/Adityaraj6/VPLE',
        demo: 'https://github.com/Adityaraj6/VPLE',
        image: two
    },
    {
        id: 3,
        projectName: 'VPLE 2.0',
        projectDesc: 'Coming Soon',
        tags: ['Operting System'],
        code: '#',
        demo: '#',
        image: three
    },
    // {
    //     id: 4,
    //     projectName: 'Android Patient Tracker',
    //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
    //     tags: ['Flutter', 'Firebase'],
    //     code: '#',
    //     demo: '#',
    //     image: four
    // },
    // {
    //     id: 5,
    //     projectName: 'E-Commerce App',
    //     projectDesc: 'A Simple E-commerce application',
    //     tags: ['React Native', 'Firebase'],
    //     code: '#',
    //     demo: '#',
    //     image: five
    // },
    // {
    //     id: 6,
    //     projectName: 'Uber Lite',
    //     projectDesc: 'Uber clone',
    //     tags: ['Flutter'],
    //     code: '#',
    //     demo: '#',
    //     image: six
    // },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: '#',
    //     demo: '#',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: '#',
    //     demo: '#',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/